import { API } from "../../../constans/Url";
import { apiClient } from "../../../helper";


const get_ProjectPreInvoice = (kode_project, callbackSuccess, callbackError) => {
  apiClient(true).get(API.PROJECT.INVOICE.PRE, {kode_project: kode_project}).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_ProjectSaveInvoice = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.PROJECT.INVOICE.SAVE, params).then(res => {
    if (res.status === 200 || res.status === 201) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

export {
  get_ProjectPreInvoice,
  get_ProjectSaveInvoice
}